<template>
  <div>
    <div class="header">
      <SimpleHeader @back="() => navigateTo('/')" />
    </div>

    <main>
      <slot />
    </main>
  </div>
</template>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}
</style>
